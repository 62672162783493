var audio;
var audioHover = new Audio();
var audioClick = new Audio();

function playAudio(src) {
  stopAudio();
  audio = new Audio(src);
  audio.loop = true;
  audio.play();
}

function playAudioOnHover() {
  audio.pause();
  audioHover = new Audio('assets/sounds/hover.mp3');
  audioHover.autoplay = true;
  audioHover.play();
  setTimeout(() => {
    audioHover.pause();
    audioHover.currentTime = 0;
    audio.play();
  }, 500)
}

function playAudioOnClick() {
  stopAudio();
  audioClick = new Audio('assets/sounds/hover.mp3');
  audioClick.play();
}

function stopAudio() {
  if (audio) {
    audio.pause();
    audio.currentTime = 0;
  }
  if (audioHover) {
    audioHover.pause();
    audioHover.currentTime = 0;
  }
  if (audioClick) {
    audioClick.pause();
    audioClick.currentTime = 0;
  }
}
